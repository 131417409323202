var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:_vm.isBordered ? 'border-light-secondary' : ''},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"lg","variant":"light-warning"}},[_c('i',{staticClass:"card-icon ti ti-brand-hipchat "})])],1),_c('div',{staticClass:"d-flex flex-column ml-1 w-100"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"text-muted font-weight-bolder "},[_vm._v("Forum")]),_c('h6',{staticClass:"font-weight-bolder small-gap-bottom"},[_vm._v(" "+_vm._s(_vm.forum.title)+" ")])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-end"}),_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"to":{
              name: _vm.checkAuthorizeRole('classroom-detail-forum-detail'),
              params: {
                id: _vm.classroomId,
                contentId: _vm.contentId,
                forumId: _vm.forum.id,
              },
            },"variant":"outline-primary"}},[_vm._v(" Lihat Forum "),_c('i',{staticClass:"regular-icon align-middle ti ti-chevron-right small-gap-right"})])],1)]),_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.localizeISODate(_vm.forum.created_at, 'ID-id'))+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }