<template>
  <div>
    <b-card class="min-height-screen">
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex align-items-center ">
          <i class="text-primary ti ti-brand-hipchat small-gap-right"></i>
          <h4 class="text-primary mb-0">Forum Kelas</h4>
        </div>

        <div>
          <!-- TODO: reenable delete all if api exist -->
          <!-- <b-button variant="outline-danger" class="small-gap-right">
            <div>
              <i class="ti ti-trash small-gap-right"> </i>
              Hapus Semua
            </div>
          </b-button> -->
          <b-button
            variant="primary"
            :to="{ name: checkAuthorizeRole('classroom-detail-forum-add') }"
          >
            <div>
              <i class="ti ti-plus small-gap-right"> </i>
              Tambah
            </div>
          </b-button>
        </div>
      </div>

      <!-- Forum Data -->
      <div
        v-show="!isLoading && classroomContentForums.length !== 0"
        class="vertical-scrollable"
      >
        <b-row>
          <!-- Task List -->
          <b-col
            v-for="forum in classroomContentForums"
            :key="forum.id"
            lg="6"
            md="6"
          >
            <forum-card is-content-creator is-bordered :forum="forum" />
          </b-col>
        </b-row>
      </div>

      <!-- Empty State -->
      <div v-show="!isLoading && classroomContentForums.length === 0">
        <b-row>
          <b-col class="d-flex justify-content-center mt-4">
            <div class="text-center pt-2">
              <b-img class="illustration-small" fluid :src="emptyStateImg" />
              <p class="mb-2">
                Belum Ada Forum Kelas
              </p>
              <b-button
                variant="primary"
                class="mb-1 btn-sm-block"
                :to="{
                  name: checkAuthorizeRole('classroom-detail-forum-add'),
                }"
              >
                Tambah Forum Kelas
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="isLoading" class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Daftar Forum
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BBadge,
  BCardImg,
  BCardText,
  BAlert,
  BImg,
  BAvatar,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ForumCard from './component/ForumCard.vue'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardImg,
    BCardText,
    BAlert,
    BImg,
    BAvatar,
    BSpinner,

    vSelect,
    flatPickr,
    ForumCard,
  },
  data() {
    return {
      // state
      isLoading: true,

      // default data
      emptyStateImg: require('@/assets/images/illustration/no-connection.svg'),
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      classroomContentForums: [],
    }
  },
  beforeRouteEnter(to, from, next) {
    const { breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = { id: to.params.id }
    next()
  },
  async mounted() {
    const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
    const url = `${baseUrl}/teachers/classrooms/${this.classroomId}`
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        'Content-Type': 'multipart/form-data',
      },
    }

    await axios.get(url, config).then((response) => {
      const forumDataByContentId = response.data.data.class_contents.filter(
        (content) => {
          return content.id === this.contentId
        }
      )

      this.classroomContentForums = [...forumDataByContentId[0].forums]
      this.isLoading = false
    })
  },
  methods: {
    avatarText,
    checkAuthorizeRole,
    getExtension(filename) {
      return filename.split('.').pop()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.preview-material {
  max-height: 200px;
  object-fit: fill;
}

.preview-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 2rem;
}

.vertical-scrollable {
  position: relative;
  padding-right: 15px;
  height: calc(100vh - 330px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}

.min-height-screen {
  min-height: calc(100vh - 190px);
}
</style>
